<template>
	<AuthWrapper>
		<AuthLayout>
			<div class="aside" />
			<div class="main">
				<AuthFormWrapper>
					<PageTitle big>
						{{ $t('forgot-password.title') }}
					</PageTitle>
					<CForm @submit.prevent="handleResetPassword">
						<div class="input-group">
							<MaterialInput
								id="contact"
								v-model="form.contact"
								type="text"
								:label="$t('forgot-password.contact')"
								:error="formErrors.contact"
							/>
						</div>

						<div v-if="networkError" class="network-error">
							<FormError :error="$t('errors.network-error')" />
						</div>

						<CButton primary :loading="loading" type="submit">
							{{ $t('forgot-password.reset') }}
						</CButton>
					</CForm>
				</AuthFormWrapper>
			</div>
		</AuthLayout>
	</AuthWrapper>
</template>

<script>
import AuthLayout from '@/shared/auth-layout/AuthLayout';
import CForm from '@/shared/cform/CForm';
import MaterialInput from '@/components/material-input/MaterialInput';
import CButton from '@/shared/cbutton/CButton';
import FormError from '@/shared/forms/FormError';
import AuthWrapper from '@/shared/auth-wrapper/AuthWrapper';
import AuthFormWrapper from '@/shared/auth-form-wrapper/AuthFormWrapper';
import PageTitle from '@/components/page-title/PageTitle';
import apiClient from '../../api';

export default {
	name: 'ForgotPassword',
	components: {
		PageTitle,
		AuthFormWrapper,
		AuthWrapper,
		CButton,
		MaterialInput,
		CForm,
		AuthLayout,
		FormError,
	},
	data() {
		return {
			form: {
				contact: '',
			},
			formErrors: {
				contact: null,
			},
			loading: false,
			networkError: false,
		};
	},
	methods: {
		async handleResetPassword() {
			const { contact } = this.form;
			this.networkError = false;
			this.loading = true;
			try {
				const response = await apiClient.forgotPassword(contact);
				if (response.status === 200) {
					this.$router.push({
						path: '/forgot-password/confirmation',
						query: { status: 'success' },
					});
				}
			} catch (error) {
				if (error.response?.data?.errors) {
					error.response.data.errors.map((value) => {
						this.formErrors[value.param] = value.msg;
					});
				} else {
					this.$router.push({
						path: '/forgot-password/confirmation',
						query: { status: 'error' },
					});
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
